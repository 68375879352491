<template>
  <div class="mx-3">
    <h2 class="px-5 py-5">{{ $t('Trial Balance Report') }}</h2>
    <v-row class="px-10 py-1">
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('folder name')" :items="folderItems" :loading="folderLoading" color="#757575"
          item-text="name" item-value="id" v-model="filters.folder" @keypress="fetchAutoCompleteFolder($event)">
        </v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('account name')" :items="accountItems" :loading="accountLoading" color="#757575"
          item-text="name" item-value="id" v-model="filters.account_id" @keypress="fetchAutoCompleteAccount($event)" @input="setFelterId">
        </v-autocomplete>
      </v-col>
      <v-col md=3>
        <AutoCompleteField :label="$t('Sub Account')" endPoint="/accounting/sub-account/auto-complete" :filter="filterId"
        v-model="filters.sub_account_id"/>
      </v-col>
      <v-col md="3">
        <v-select :dark="$store.state.isDarkMode" v-model="filters.final_account" :items="finalItems" :label="$t('Final Account')" item-text="name"
          item-value="value"></v-select>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filters.from">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filters.to">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('cost center')" :items="costCenterItems" color="#757575" item-text="name"
          item-value="id" :loading="costCenterLoading" v-model="filters.cost_center"
          @keypress="fetchAutoCompleteCostCenter($event)">
        </v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.term" :loading="termsLoading" :items="terms" :label="$t('term')"
          prepend-icon="mdi-counter" item-text="name" item-value="id" @keypress="fetchTerm($event)"
          color="#757575"></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-row>
          <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Empty')" class="mx-4 mb-4" v-model="filters.not_empty" hide-details></v-checkbox>
          <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Not Equal')" class="mx-4 mb-4" v-model="filters.equal" hide-details></v-checkbox>
        </v-row>
      </v-col>
      <v-col md="3">
        <v-row>
          <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Show Sub Accounts')" class="mx-4 mb-4"
           v-model="filters.active_sub_account" hide-details></v-checkbox>
        </v-row>
      </v-col>
      <v-col md="6">

      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="blue" dark block @click="exportToExcel(filters)">{{ $t('hr.time.export') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems"
      class="elevation-1 px-10" hide-default-footer :items-per-page="10">
      <template v-slot:item.actions="{ item }">
        <!-- v-if="returnAbility('entry:show') && (!item.entry.operate) && (item.entry.owner_type != null)" -->
        <v-btn @click="goTodestination(item)" color="blue" icon>
          <v-icon class="ml-1">mdi-eye</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-pagination v-if="!loading && tableItems.length" @input="getAll" v-model="page"
      :length="Math.ceil(total / 10)"></v-pagination>
    <div style="height: 20px;">
    </div>
    <div class="d-flex  account-entry__chip__wrapper">
      <div class="account-entry__chip">
        {{ $t('total debit') }}
        <span>{{ totalDebit }}</span>
      </div>
      <div class="account-entry__chip">
        {{ $t('total credit') }}
        <span>{{ totalCredit }}</span>
      </div>
      <div class="account-entry__chip">
        {{ $t('balance') }}
        <span>{{ remainingBalance }}</span>
      </div>
    </div>
    <div style="height: 20px;">
    </div>
  </div>
</template>
<script>
import axios from "axios";
import i18n from '@/i18n';
import debounce from "../../../../helpers/debounce";
import { saveAs } from 'file-saver';
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";


export default {
  components: { AutoCompleteField },
  computed: {
    finalItems() {
      return [
        {
          name: this.$t("Trading"),
          value: "trading",
        },
        {
          name: this.$t("Profits and Losses"),
          value: "profits_and_losses",
        },
        {
          name: this.$t("Balance"),
          value: "balance",
        },
      ];
    },
  },
  data: () => ({
    filterId: null,
    totalDebit: 0,
    totalCredit: 0,
    remainingBalance: 0,
    termsLoading: false,
    terms: [],
    costCenterItems: [],
    costCenterLoading: false,
    accountItems: [],
    accountLoading: false,
    folderItems: [],
    folderLoading: false,
    tableItems: [],
    loading: false,
    page: 1,
    total: 1,
    headers: [
      { text: i18n.t("serial number"), value: "serial" },
      { text: i18n.t('account name'), value: "account_name" },
      { text: i18n.t('Sub Account'), value: "sub_account_name" },
      { text: i18n.t('Final Account'), value: "final_account" },
      { text: i18n.t('total debit'), value: "debit" },
      { text: i18n.t('total credit'), value: "credit" },
      { text: i18n.t('balance'), value: "balance" },
      { text: "", value: "actions" },
    ],
    items: [],
    filters: {
      account_id: null,
      cost_center: null,
      folder: null,
      final_account: null,
      from: null,
      to: null,
      term: null,
      equal: false,
      not_empty: false,
      active_sub_account: false,
      sub_account_id: null,
    },
  }),
  methods: {
    setFelterId() {
      this.filterId = this.filters.account_id;
    },
    async exportToExcel(queryParams) {
      try {
        const excel = await axios.get(`/accounting/account/trail-balance/export`, {
          params: {
            ...queryParams,
          },
          responseType: 'blob'
        });
        saveAs(excel.data)
      } catch (err) {
        console.log('err', err);
      } finally {
      }
    },
    async fetchTerm() {
      try {
        this.termsLoading = true;
        const response = await axios.get("terms", {
          params: {
            size: 100000,
          },
        });
        this.terms = response.data.terms;
      } catch (err) {
      } finally {
        this.termsLoading = false;
      }
    },
    clearFilter() {
      this.filters.account_id = undefined;
      this.filters.folder = undefined;
      this.filters.cost_center = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.term = undefined;
      this.filters.final_account = undefined;
      this.filters.equal = undefined;
      this.filters.not_empty = undefined;
      this.filters.sub_account_id = undefined;
      this.page = 1;
      this.getAll();
    },
    goTodestination(item) {
      this.$router.push(`/accountStatement/${item.id}`);
    },
    async getAll(page) {
      try {
        if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("/accounting/account/trail-balance", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        const arr = Array.from(res.data.data)
        if (Array.isArray(arr)) {
          console.log(arr);
          this.tableItems = arr.map((el) => {
            return {
              ...el,
            };
          });
        }
        this.total = res.data.total;
        this.totalDebit = res.data.amount.debit;
        this.totalCredit = res.data.amount.credit;
        this.remainingBalance = res.data.amount.balance;
      } finally {
        this.loading = false;
      }
    },
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteFolder(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.folderLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/folder/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.folderItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.folderLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterLoading = false;
        }
      }, 500)();
    },
  },
  created() {
    this.getAll();
  },
};
</script>

<style></style>